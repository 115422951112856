(function($) {

    /* Select placeholder */

    $('.input--select .input__widget').on('change', function () {
        if( $(this).val() === 'placeholder' ) {
            $(this).parent('.input').addClass('input--placeholder-is-chosen')
        } else {
            $(this).parent('.input').removeClass('input--placeholder-is-chosen')
        }
    });


    /* Expand text area manually */

    $('.input--extendable .input__widget').each(function () {
        this.setAttribute('style', 'height:' + (this.scrollHeight) + 'px;overflow-y: hidden;');
    }).on('input', function () {
        this.style.height = 'auto';
        this.style.height = (this.scrollHeight) + 'px';
    });

    /* Expand text area via jQuery plugin */

    $('.input--extendable-via-plugin .input__widget').expanding();



    /* Adjustable inputs */

    var $sizeChecker = jQuery('.size-checker');

    function selectSetWidth($field) {
        if ( $field.is('select') ) {
            $sizeChecker.html($field.children("option:selected").html());
        } else if($field.is('div')) {
            $sizeChecker.html($field.html());
        } else {
            $sizeChecker.html($field.val() ? $field.val() : $field.attr('placeholder'));
        }
        $sizeChecker.css({
            'border-left': $field.css('border-left'),
            'border-right': $field.css('border-right'),
            'padding-left': $field.css('padding-left'),
            'padding-right': $field.css('padding-right'),
            'font-family': $field.css('font-family'),
            'font-weight': $field.css('font-weight'),
            'font-size': $field.css('font-size'),
            'line-height': $field.css('line-height'),
            'letter-spacing': $field.css('letter-spacing')
        });
        var width = $sizeChecker.outerWidth();

        /* Possible with limitation with JavaScript, but better do it via CSS.
         *  If done with CSS can be changed to different values in different areas
        */
        // if ( width > 500 ) {
        //     width = 500;
        // }

        if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1){
            if ( $field.is('select') ) {
                width = width + 10;
                $field.css('margin-left', '-4px' );
            }
        }

        $field.css('width', Math.max( 50, width ) );

        $sizeChecker.html(''); /* Clean up for next iteration */
    }

    $('.input--adjustable .input__widget').each(function () {
        selectSetWidth( $(this) );
    });

    $('.input--adjustable .input__widget').on('input', function () {
        selectSetWidth( $(this) );
    });

    $('.input--adjustable .input__widget').on('change', function () {
        selectSetWidth( $(this) );
    });




    /* Dropdowns */

    $('.input__widget').on('click focus', function () {
      let name =  document.getElementById("firt-name-input-vale").value
      let lastname =  document.getElementById("firt-lastname-input-vale").value
        $('.input').removeClass('input--expanded'); /* Close rest of dropdowns */
        $(this).parents('.input').addClass('input--expanded');
        $(this).parents('.input').find('.lookup__field').focus();
        if (name != '' && lastname != '') {
          $("#main-info-submit-button").removeClass("button--disabled")
          $("#main-info-submit-button").removeClass("unclickable")
        }
    });
    $('.input__widget').on('keyup', function () {
      let name =  document.getElementById("firt-name-input-vale").value
      let lastname =  document.getElementById("firt-lastname-input-vale").value
      if (name == '') {
        $("#firt-name-input").addClass("input--error")
        $(".output-name").removeClass("hidden")
        $("#main-info-submit-button").addClass("button--disabled")
        $("#main-info-submit-button").addClass("unclickable")
      }else if (lastname == '') {
        $("#firt-lastname-input").addClass("input--error")
        $(".output-lastname").removeClass("hidden")
        $("#firt-name-input").removeClass("input--error")
        $(".output-name").addClass("hidden")
        $("#main-info-submit-button").addClass("button--disabled")
        $("#main-info-submit-button").addClass("unclickable")
      }else if (name != '' && lastname != '') {
        $("#firt-lastname-input").removeClass("input--error")
        $(".output-lastname").addClass("hidden")
        $("#firt-name-input").removeClass("input--error")
        $(".output-name").addClass("hidden")
        $("#main-info-submit-button").removeClass("button--disabled")
        $("#main-info-submit-button").removeClass("unclickable")
      }
    });

    $('.input__widget').on('keyup', function () {
      let video_link =  document.getElementById("video-link-value").value
      let video_title =  document.getElementById("video-add-title-field").value
      let video_subtitle =  document.getElementById("video-add-subtitle-field").value
      let video_file =  document.getElementById("video-add-cover-upload").value
      let audio_title =  document.getElementById("audio-add-title-field").value
      let audio_subtitle =  document.getElementById("audio-add-subtitle-field").value
      let audio_file =  document.getElementById("add-track-upload").value
      let project_title =  document.getElementById("project-add-title-field").value
      let project_description =  document.getElementById("project-add-description-field").value

      if (video_file == '') {
        $("#video-add-file").addClass("upload-bar--error")
        $(".output-video-cover").removeClass("hidden")
        $("#video-add-subtitle-input").removeClass("input--error")
        $(".output-video-subtitle").addClass("hidden")
        $("#video-link-input").removeClass("input--error")
        $(".output-video-link").addClass("hidden")
        $("#video-add-title-input").removeClass("input--error")
        $(".output-video-title").addClass("hidden")
      }else if (video_link == '') {
        $("#video-link-input").addClass("input--error")
        $(".output-video-link").removeClass("hidden")
        $("#video-add-title-input").removeClass("input--error")
        $(".output-video-title").addClass("hidden")
        $("#video-add-subtitle-input").removeClass("input--error")
        $(".output-video-subtitle").addClass("hidden")
        $("#video-add-file").removeClass("upload-bar--error")
        $(".output-video-cover").addClass("hidden")
      }else if (video_title == '') {
        $("#video-add-title-input").addClass("input--error")
        $(".output-video-title").removeClass("hidden")
        $("#video-link-input").removeClass("input--error")
        $(".output-video-link").addClass("hidden")
        $("#video-add-subtitle-input").removeClass("input--error")
        $(".output-video-subtitle").addClass("hidden")
        $("#video-add-file").removeClass("upload-bar--error")
        $(".output-video-cover").addClass("hidden")
      }else if (video_subtitle == '') {
        $("#video-add-subtitle-input").addClass("input--error")
        $(".output-video-subtitle").removeClass("hidden")
        $("#video-link-input").removeClass("input--error")
        $(".output-video-link").addClass("hidden")
        $("#video-add-title-input").removeClass("input--error")
        $(".output-video-title").addClass("hidden")
        $("#video-add-file").removeClass("upload-bar--error")
        $(".output-video-cover").addClass("hidden")
      }else {
        $("#video-add-file").removeClass("upload-bar--error")
        $(".output-video-cover").addClass("hidden")
        $("#video-add-subtitle-input").removeClass("input--error")
        $(".output-video-subtitle").addClass("hidden")
        $("#video-link-input").removeClass("input--error")
        $(".output-video-link").addClass("hidden")
        $("#video-add-title-input").removeClass("input--error")
        $(".output-video-title").addClass("hidden")
      }
      if (audio_file == '') {
        $("#audio-add-file").addClass("upload-bar--error")
        $(".output-audio-cover").removeClass("hidden")
        $("#audio-add-title-input").removeClass("input--error")
        $(".output-audio-title").addClass("hidden")
        $("#audio-add-subtitle-input").removeClass("input--error")
        $(".output-audio-subtitle").addClass("hidden")
      }else if (audio_title == '') {
        $("#audio-add-title-input").addClass("input--error")
        $(".output-audio-title").removeClass("hidden")
        $("#audio-add-subtitle-input").removeClass("input--error")
        $(".output-audio-subtitle").addClass("hidden")
        $("#audio-add-file").removeClass("upload-bar--error")
        $(".output-audio-cover").addClass("hidden")
      }else if (audio_subtitle == '') {
        $("#audio-add-subtitle-input").addClass("input--error")
        $(".output-audio-subtitle").removeClass("hidden")
        $("#audio-add-title-input").removeClass("input--error")
        $(".output-audio-title").addClass("hidden")
        $("#audio-add-file").removeClass("upload-bar--error")
        $(".output-audio-cover").addClass("hidden")
      }else {
        $("#audio-add-subtitle-input").removeClass("input--error")
        $(".output-audio-subtitle").addClass("hidden")
        $("#audio-add-title-input").removeClass("input--error")
        $(".output-audio-title").addClass("hidden")
        $("#audio-add-file").removeClass("upload-bar--error")
        $(".output-audio-cover").addClass("hidden")
      }
      if (project_title == '') {
        $("#project-add-title-input").addClass("input--error")
        $(".output-project-title").removeClass("hidden")
        $("#project-add-description-input").removeClass("input--error")
        $(".output-project-description").addClass("hidden")
      }else if (project_description == '') {
        $("#project-add-description-input").addClass("input--error")
        $(".output-project-description").removeClass("hidden")
        $("#project-add-title-input").removeClass("input--error")
        $(".output-project-title").addClass("hidden")
      }else {
        $("#project-add-description-input").removeClass("input--error")
        $(".output-project-description").addClass("hidden")
        $("#project-add-title-input").removeClass("input--error")
        $(".output-project-title").addClass("hidden")
      }
      if (video_link != '' && video_title != '' && video_subtitle != '' && video_file != '') {
        $("#submit-video-button").removeClass("button--disabled")
        $("#submit-video-button").removeClass("unclickable")
      }else if (audio_title != '' && audio_subtitle != '' && audio_file != '') {
        $("#submit-audio-button").removeClass("button--disabled")
        $("#submit-audio-button").removeClass("unclickable")
      }else if (project_title != '' && project_description != '') {
        $("#submit-project-button").removeClass("button--disabled")
        $("#submit-project-button").removeClass("unclickable")
      }else {
        $("#submit-video-button").addClass("button--disabled")
        $("#submit-video-button").addClass("unclickable")
        $("#submit-audio-button").addClass("button--disabled")
        $("#submit-audio-button").addClass("unclickable")
        $("#submit-project-button").addClass("button--disabled")
        $("#submit-project-button").addClass("unclickable")
      }
    });

    $('#project-add-description-field').on('keyup', function () {

      var numWords = $(this).val().trim().split(" ").length;
      document.getElementById("description-count-edited").innerHTML = numWords +"/200";
      if (numWords > 200) {
          $('#description-count-edited').css("color", "red")
          $("#submit-project-button").addClass("button--disabled")
          $("#submit-project-button").addClass("unclickable")
      } else {
          $('#description-count-edited').css("color", "#757575")
          $("#submit-project-button").removeClass("button--disabled")
          $("#submit-project-button").removeClass("unclickable")
      }
    });

    $('#video-add-cover-upload').on('change', function () {
      let video_link =  document.getElementById("video-link-value").value
      let video_title =  document.getElementById("video-add-title-field").value
      let video_subtitle =  document.getElementById("video-add-subtitle-field").value
      let video_file =  document.getElementById("video-add-cover-upload").value

      if (video_file == '') {
        $("#video-add-file").addClass("upload-bar--error")
        $(".output-video-cover").removeClass("hidden")
        $("#video-add-subtitle-input").removeClass("input--error")
        $(".output-video-subtitle").addClass("hidden")
        $("#video-link-input").removeClass("input--error")
        $(".output-video-link").addClass("hidden")
        $("#video-add-title-input").removeClass("input--error")
        $(".output-video-title").addClass("hidden")
      }else if (video_link == '') {
        $("#video-link-input").addClass("input--error")
        $(".output-video-link").removeClass("hidden")
        $("#video-add-title-input").removeClass("input--error")
        $(".output-video-title").addClass("hidden")
        $("#video-add-subtitle-input").removeClass("input--error")
        $(".output-video-subtitle").addClass("hidden")
        $("#video-add-file").removeClass("upload-bar--error")
        $(".output-video-cover").addClass("hidden")
      }else if (video_title == '') {
        $("#video-add-title-input").addClass("input--error")
        $(".output-video-title").removeClass("hidden")
        $("#video-link-input").removeClass("input--error")
        $(".output-video-link").addClass("hidden")
        $("#video-add-subtitle-input").removeClass("input--error")
        $(".output-video-subtitle").addClass("hidden")
        $("#video-add-file").removeClass("upload-bar--error")
        $(".output-video-cover").addClass("hidden")
      }else if (video_subtitle == '') {
        $("#video-add-subtitle-input").addClass("input--error")
        $(".output-video-subtitle").removeClass("hidden")
        $("#video-link-input").removeClass("input--error")
        $(".output-video-link").addClass("hidden")
        $("#video-add-title-input").removeClass("input--error")
        $(".output-video-title").addClass("hidden")
        $("#video-add-file").removeClass("upload-bar--error")
        $(".output-video-cover").addClass("hidden")
      }else {
        $("#video-add-file").removeClass("upload-bar--error")
        $(".output-video-cover").addClass("hidden")
        $("#video-add-subtitle-input").removeClass("input--error")
        $(".output-video-subtitle").addClass("hidden")
        $("#video-link-input").removeClass("input--error")
        $(".output-video-link").addClass("hidden")
        $("#video-add-title-input").removeClass("input--error")
        $(".output-video-title").addClass("hidden")
      }
      if (video_link != '' && video_title != '' && video_subtitle != '' && video_file != '') {
        $("#submit-video-button").removeClass("button--disabled")
        $("#submit-video-button").removeClass("unclickable")
      }else {
        $("#submit-video-button").addClass("button--disabled")
        $("#submit-video-button").addClass("unclickable")
      }
    });

    var numWordsA = 0;

    $('#audio-add-composer-field').on('keyup', function () {
      let audio_title =  document.getElementById("audio-add-title-field").value
      let audio_subtitle =  document.getElementById("audio-add-subtitle-field").value
      let audio_file =  document.getElementById("add-track-upload").value
      numWordsA = $(this).val().trim().split(" ").length;
      $(this).parents('.input').find('.input__counter').text(numWordsA + '/80');
      if (numWordsA > 80) {
          $('.input__counter').css("color", "red")
          $("#submit-audio-button").addClass("button--disabled")
          $("#submit-audio-button").addClass("unclickable")
      } else {
          $('.input__counter').css("color", "#000000")
          if (audio_title != '' && audio_subtitle != '' && audio_file != '') {
            $("#submit-audio-button").removeClass("button--disabled")
            $("#submit-audio-button").removeClass("unclickable")
          }
      }
    });

    $('#add-track-upload').on('change', function () {
      let audio_title =  document.getElementById("audio-add-title-field").value
      let audio_subtitle =  document.getElementById("audio-add-subtitle-field").value
      let audio_file =  document.getElementById("add-track-upload").value

      if (audio_file == '') {
        $("#audio-add-file").addClass("upload-bar--error")
        $(".output-audio-cover").removeClass("hidden")
        $("#audio-add-title-input").removeClass("input--error")
        $(".output-audio-title").addClass("hidden")
        $("#audio-add-subtitle-input").removeClass("input--error")
        $(".output-audio-subtitle").addClass("hidden")
      }else if (audio_title == '') {
        $("#audio-add-title-input").addClass("input--error")
        $(".output-audio-title").removeClass("hidden")
        $("#audio-add-subtitle-input").removeClass("input--error")
        $(".output-audio-subtitle").addClass("hidden")
        $("#audio-add-file").removeClass("upload-bar--error")
        $(".output-audio-cover").addClass("hidden")
      }else if (audio_subtitle == '') {
        $("#audio-add-subtitle-input").addClass("input--error")
        $(".output-audio-subtitle").removeClass("hidden")
        $("#audio-add-title-input").removeClass("input--error")
        $(".output-audio-title").addClass("hidden")
        $("#audio-add-file").removeClass("upload-bar--error")
        $(".output-audio-cover").addClass("hidden")
      }else {
        $("#audio-add-subtitle-input").removeClass("input--error")
        $(".output-audio-subtitle").addClass("hidden")
        $("#audio-add-title-input").removeClass("input--error")
        $(".output-audio-title").addClass("hidden")
        $("#audio-add-file").removeClass("upload-bar--error")
        $(".output-audio-cover").addClass("hidden")
      }
      if (audio_title != '' && audio_subtitle != '' && audio_file != '') {
        $("#submit-audio-button").removeClass("button--disabled")
        $("#submit-audio-button").removeClass("unclickable")
      }else {
        if (numWordsA > 80) {
            $("#submit-audio-button").addClass("button--disabled")
            $("#submit-audio-button").addClass("unclickable")
        } else {
            $("#submit-audio-button").removeClass("button--disabled")
            $("#submit-audio-button").removeClass("unclickable")
        }
      }
    });


    $(document).on('click touchstart', function(event) {
        if (!$(event.target).closest('.input').length) {
            $('.input').removeClass('input--expanded');
        }
    });

    $(document).on('keyup', function(event) {
        if (event.keyCode === 27) {
            $('.input').removeClass('input--expanded');
        }
    });

    $('.input__suggest').on('click', function () {
        var value;

        if ( ! $(this).hasClass('input__suggest--placeholder')  ) {
            value = $(this).text();
        } else {
            value = '';
        }

        $(this).parents('.input').removeClass('input--expanded');
        $(this).parents('.input').find('.input__widget').val(value);
    });


    /* Custom Pronoun */

    $(".pronoun-clicker").on("click", function(){
      let name =  document.getElementById("firt-name-input-vale").value
      let lastname =  document.getElementById("firt-lastname-input-vale").value
        if (name != '' && lastname != '') {
          $("#main-info-submit-button").removeClass("button--disabled")
          $("#main-info-submit-button").removeClass("unclickable")
        }
        if ($("#pronoun-field").val() === "Custom") {
           $("#custom-pronouns-area").show()
        } else if ($("#pronoun-field").val() === "Pronouns") {
          $("#custom-pronouns-area").hide()
          $("#custom-pronouns-field").val("Pronouns")
        } else {
            $("#custom-pronouns-area").hide()
            $("#custom-pronouns-field").val("")
        }
    })

    /* Counter sample */
    $('#short-bio-text-area').on('keyup', function () {
      var numWords = $(this).val().trim().split(" ").length;
        $(this).parents('.input').find('.input__counter').text(numWords + '/80');
        if (numWords > 80) {
            $('.input__counter').css("color", "red")
            $("#main-info-submit-button").addClass("button--disabled")
            $("#main-info-submit-button").addClass("unclickable")
        } else {
            $('.input__counter').css("color", "#757575")
            let name =  document.getElementById("firt-name-input-vale").value
            let lastname =  document.getElementById("firt-lastname-input-vale").value
            if (name != '' && lastname != '') {
                $("#main-info-submit-button").removeClass("button--disabled")
                $("#main-info-submit-button").removeClass("unclickable")
              }
        }
    });

    // $(".edit-info__section").hover(function(){
    //   let name =  document.getElementById("firt-name-input-vale").value
    //   let lastname =  document.getElementById("firt-lastname-input-vale").value
    //     if (name != '' && lastname != '') {
    //       $("#main-info-submit-button").removeClass("button--disabled")
    //       $("#main-info-submit-button").removeClass("unclickable")
    //     }
    // });


    $('#full-bio-text-area').on('keyup', function () {
        var numWords = $('#full-bio-text-area').val().trim().split(" ").length;
        let name =  document.getElementById("firt-name-input-vale").value
        let lastname =  document.getElementById("firt-lastname-input-vale").value
        if (name != '' && lastname != '') {
            $("#main-info-submit-button").removeClass("button--disabled")
            $("#main-info-submit-button").removeClass("unclickable")
          }

        $(this).parents('.input').find('.input__counter').text(numWords + '/1000');
        if (numWords > 1000) {
            $('.input__counter').css("color", "red")
            $("#material-submit-button").addClass("button--disabled")
            $("#material-submit-button").addClass("unclickable")
        } else {
            $('.input__counter').css("color", "#757575")
            if (name != '' && lastname != '') {
                $("#main-info-submit-button").removeClass("button--disabled")
                $("#main-info-submit-button").removeClass("unclickable")
              }
        }
    });
    $('#textarea_bio').on('change', function () {
        var numWords = $('#textarea_bio').val().trim().split(" ").length;
        let name =  document.getElementById("firt-name-input-vale").value
        let lastname =  document.getElementById("firt-lastname-input-vale").value
        if (name != '' && lastname != '') {
            $("#main-info-submit-button").removeClass("button--disabled")
            $("#main-info-submit-button").removeClass("unclickable")
          }

        $(this).parents('.input').find('.input__counter').text(numWords + '/80');
        if (numWords > 80) {
            $('.input__counter').css("color", "red")
            $("#material-submit-button").addClass("button--disabled")
            $("#material-submit-button").addClass("unclickable")
        } else {
            $('.input__counter').css("color", "#757575")
            if (name != '' && lastname != '') {
                $("#main-info-submit-button").removeClass("button--disabled")
                $("#main-info-submit-button").removeClass("unclickable")
              }
        }
    });

})(jQuery);
